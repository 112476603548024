
import { useEffect, useState } from "react"
import Logo from "../../assets/logo1.jpeg"
import "./forgotPassword.scss"
import axios from "axios"
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const [email,setEmail]= useState("");
    const [errorMessage,setErrorMessage]= useState("");
    const [isLoading,setIsLoading]= useState(false);
    const [isMailSent,setIsMailSent]= useState(false);
    const URL = process.env.REACT_APP_API_URL;
    const [messageApi, contextHolder] = message.useMessage();
    const isMobile = window.innerWidth <= 768;
    const navigate = useNavigate();

       const sendResetRequest= async ()=>{
            setIsLoading(true);
            try{
                await axios.post(URL+"/users/user/forgetPassword/"+email )
                setIsLoading(false);
                setIsMailSent(true);
                messageApi.open({
                    type: 'success',
                    content: <RegistationSuccess/>,
                    className: 'custom-class',
                    style:isMobile ? successMobile : successWeb,
                  });
                setTimeout(()=>{
                    navigate('/login');
                  },3000)
            }
            catch (e){
                console.log(e.response.data.message)
                setErrorMessage(e?.response?.data?.message || "something went wrong")
                setIsLoading(false);
            }
           
        }
      

        const handleSubmit = (e)=>{
            e.preventDefault()
            sendResetRequest();
            setEmail("");
            setErrorMessage("");
        }

        let successMobile = {
            marginTop: '10vh',
            height: "200px",
          }
        
          let successWeb ={
            marginTop: '10vh',
            // marginRight:'50vw',
            height: "200px",
          }
        
          const RegistationSuccess = ()=>{
            return (
              <div className="success">
                <h4>Reset Link sent Successfully</h4>
                <p>please check your email for reset link</p>
              </div>
            )
          }

    return (
        <>
        {contextHolder}
            <div className="forgotPassword-container">
                <div className="main-container">
                        
                    <div className="img-container">
                        <img src={Logo} width={"150"} height={"150px"} alt="" />
                    </div>
                    
                    <div className="f-info">
                        <h4>Forgot Password</h4>
                        <span>Enter your email to reset your password.</span>
                        <span>We will send you a link to reset your password.</span>
                        
                    </div>
                    <div className="form-container-f">
                        <form>
                            <div>
                                <label htmlFor="f-email">Email</label>
                                <input type='email' id="f-email" placeholder="Enter your Email" value={email} onChange={(e)=>setEmail(e.target.value)}></input>
                                <span className={errorMessage.length>0 ? "f-error" : "f-success"}>{errorMessage}</span>
                            </div>
                            
                            <div>
                                {
                                    !isMailSent ?
                                    <button className="f-button sent-email" onClick={handleSubmit} disabled={isLoading} style={{"backgroundColor":"#012e6b","color":"white","fontSize":"0.8rem"}}>{isLoading ? "loading" :"Submit"}</button>
                                    :
                                    <button className="f-button success-email" disabled={true}>Link sent Successfully</button>
                                }
                               
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;