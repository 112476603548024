// Renderers.js
import React from 'react';

// Video Renderer
export const VideoRenderer = ({ story, action, isPaused, config, seeMore }) => {
    const { width, height } = config;
    return (
        <div style={config}>
            <video
                src={story.url}
                controls
                autoPlay
                style={{ width: '100%', height: '100%' }}
                onEnded={() => {
                    action('next')
                }}
            />
        </div>
    );
};

export const videoTester = (story) => ({
    condition: story.type === 'video',
    priority: 3,
});

// Image Renderer
export const ImageRenderer = ({ story, action, isPaused, config }) => {
    const { width, height } = config;
    return (
        <div style={{ width, height }}>
            <img
                src={story.url}
                alt="story"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
        </div>
    );
};

export const imageTester = (story) => ({
    condition: story.type === 'image',
    priority: 1,
});
