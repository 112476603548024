export const generateVideoThumbnail = async (videoFile) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");
      video.src = URL.createObjectURL(videoFile);

      video.onloadeddata = () => {
        video.currentTime = 1; // Capture the frame at 1 second
      };

      video.onseeked = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          resolve(blob); // Convert canvas to Blob (JPEG image)
        }, "image/jpeg");
      };

      video.onerror = (error) => {
        reject(new Error("Failed to generate thumbnail"));
      };
    });
  };