import { useState } from "react";
import { makeRequest } from "../../axios";
import "./update.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AWS from 'aws-sdk';
import { Password } from "@mui/icons-material";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { notification } from 'antd';

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Update = ({ setOpenUpdate, user }) => {
  const [cover, setCover] = useState(null);
  const [profile, setProfile] = useState(null);
  const [texts, setTexts] = useState({
    password: user.password,
    address: user.userLocation,
  });

  const { currentUser,setCurrentUser } = useContext(AuthContext);



  const S3_BUCKET = 'streesakti';

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const s3 = new AWS.S3();


  // const upload = async (file) => {
  //   console.log(file)
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     const res = await makeRequest.post("/upload", formData);
  //     return res.data;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleChange = (e) => {
    setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (user) => {
      return makeRequest.put(`/users/user/updateUser/${currentUser.id}`, user);
    },
    {
      onSuccess: (data,variables) => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        setCurrentUser((prev)=>{
          return {...prev,profilePic:variables.userProfileImagePath}})
        console.log(data)
        notification.success({
          message: 'Profile updated successfully',
        });
      },
    }
  );

  const handleClick = async (e) => {
    e.preventDefault();

    // Check if any field is empty
    // if (!texts.password || !texts.address) {
    //   notification.error({
    //     message: 'Error',
    //     description: 'All fields are required!',
    //   });
    //   return; // Exit the function if validation fails
    // }

    let coverUrl;
    let profileUrl;

    if (cover) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${cover.name}`,
        Body: cover,
      };
      try {
        const data = await s3.upload(params).promise();
        console.log("File uploaded successfully. File URL:", data.Location);

        coverUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      coverUrl = user.userCoverProfileImage ? user.userCoverProfileImage : "";
    }

    if (profile) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${profile.name}`,
        Body: profile,
      };
      try {
        const data = await s3.upload(params).promise();
        console.log("File uploaded successfully. File URL:", data.Location);

        profileUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      profileUrl = user.userProfileImage ? user.userProfileImage : "";
    }

    mutation.mutate({
      userDateOfBirth: "",
      userAddress: texts.address,
      userProfileImagePath: profileUrl,
      userCoverProfileImagePath: coverUrl,
      userPassword: texts.password,
    });

    setOpenUpdate(false);
    setCover(null);
    setProfile(null);
  };

  return (
    <div className="update">
      <div className="wrapper">
        <h1>Update Your Profile</h1>
        <form>
          <div className="files">
            <label htmlFor="cover">
              <span>Cover Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    cover
                      ? URL.createObjectURL(cover)
                      : user.userCoverProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="cover"
              style={{ display: "none" }}
              onChange={(e) => setCover(e.target.files[0])}
            />
            <label htmlFor="profile">
              <span>Profile Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    profile
                      ? URL.createObjectURL(profile)
                      : user.userProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="profile"
              style={{ display: "none" }}
              onChange={(e) => setProfile(e.target.files[0])}
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="text"
              value={texts.password}
              name="password"
              onChange={handleChange}
              placeholder="update password / enter old password"
            />
          </div>

          <div>
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={texts.address}
              onChange={handleChange}
            />
          </div>
          <button onClick={handleClick}>Update</button>
        </form>
        <button className="close" onClick={() => setOpenUpdate(false)}>
          close
        </button>
      </div>
    </div>

  );
}

export default Update;
