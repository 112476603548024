import React from 'react';
import Stories, { WithSeeMore } from 'react-insta-stories';
import moment from 'moment';
import { makeRequest } from '../../../axios';
import CusListing from './CusListing';
import { VideoRenderer, videoTester, ImageRenderer, imageTester } from "../Renderers.js";

const CusComponent = ({ cusData, setCusModalVisible }) => {
  const isMobile = window.innerWidth < 786;
  if(!cusData){
    return null;
  }
  return (

    <div >
      <Stories
        stories={cusData.map((story) => {
          return ({
            url: story.storyUrl,
            type: story.storyType.includes("video") ? "video" : "image",
            //  on click i want to call close function of see more
            header: {
              heading: "Your story",
              subheading: story.storyCreatedAt,
              profileImage: story.userProfileImagePath,
            },
            seeMore: ({ close }) => {
              return <div style={{
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
              }}
                onClick={close}

              >

                <div
                  style={{
                    backgroundColor: "white",
                    height: "70%", width: "100%",
                    position: "absolute",
                    bottom: "0px",
                    // padding: "15px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
                    overflow: "hidden",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <CusListing viewersData={story.usersWhoViewed} storyId={story.storyId} setCusModalVisible={setCusModalVisible} />
                </div>
              </div>;
            },
          })
        })}
        renderers={[
          {
            renderer: ImageRenderer,
            tester: imageTester,
          },
        ]}
        defaultInterval={2500}
        width={isMobile ? "100vw" : 423}
        height={isMobile ? "100vh" : 768}
        storyStyles={{
          
          
          width: '100%',
          height: '100%',
      }}
      />
    </div>
  );
};

export default CusComponent;
