import { useContext, useState } from "react";
import "./stories.scss";
import { AuthContext } from "../../context/authContext";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { Image, Modal, Input, Upload, Button, message } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk';
import heic2any from "heic2any";
import CusComponent from "./cus/CusComponent";
import StoriesModal from "./storiesModal/StoriesModal";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { generateVideoThumbnail } from "../../helpers/thumbnailGenerator";

const MyStories = () => {
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [isModalVisible, setModalVisible] = useState(false);
  const [newStoryFile, setNewStoryFile] = useState(null);
  const [newStoryText, setNewStoryText] = useState("");
  const [fileType, setFileType] = useState("");
  const [storiesModalVisible, setStoriesModalVisible] = useState(false);
  const [selectedStories, setSelectedStories] = useState([]);
  const [cusModalVisible, setCusModalVisible] = useState(false);

  const S3_BUCKET = 'streesakti';
  const awsRegion = process.env.REACT_APP_AWS_REGION;
  const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const s3 = new AWS.S3();

  const { isLoading: storiesLoading, error: storiesError, data: storiesData } = useQuery(["stories"], () =>
    makeRequest.get("/users/story/getStories").then(res => res.data.storiesDetails)
  );

  const { isLoading: cusLoading, error: cusError, data: cusData } = useQuery(["getCurrentUserStories"], () =>
    makeRequest.get(`/users/story/getViewedStories/${currentUser.id}`).then(res => res.data.viewedStories)
  );

  const mutation = useMutation(
    newStory => makeRequest.post(`/users/story/createStory/${currentUser.id}`, newStory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stories"]);
        queryClient.invalidateQueries(["getCurrentUserStories"]);
        message.success("Story added successfully!");
      },
      onError: () => message.error("Failed to add story. Please try again."),
    }
  );

  const handleModalOk = async () => {
    if (!newStoryFile) return;

    let fileToUpload = newStoryFile;
    let thumbnailUrl = null;

    // If the file is an HEIC image, convert it to JPEG
    if (fileType === "image" && newStoryFile.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({ blob: newStoryFile, toType: "image/jpeg" });
        fileToUpload = new File([convertedBlob], newStoryFile.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
      } catch (error) {
        message.error(`Conversion Failed: ${error.message}`);
        return;
      }
    }

    // Generate thumbnail if the file is a video
    if (fileType === "video") {
      try {
        const thumbnailBlob = await generateVideoThumbnail(newStoryFile); // Custom function to generate thumbnail
        const thumbnailParams = {
          Bucket: S3_BUCKET,
          Key: `posts/thumbnails/${newStoryFile.name.replace(/\.[^/.]+$/, ".jpg")}`,
          Body: thumbnailBlob,
          ContentType: "image/jpeg",
        };
        const thumbnailData = await s3.upload(thumbnailParams).promise();
        thumbnailUrl = thumbnailData.Location;
      } catch (error) {
        message.error(`Thumbnail Generation Failed: ${error.message}`);
        return;
      }
    }

    // Upload the file (image or video) to S3
    const params = {
      Bucket: S3_BUCKET,
      Key: `posts/${fileToUpload.name}`,
      Body: fileToUpload,
    };

    try {
      const { Location } = await s3.upload(params).promise();
      // Send the video/image URL and the thumbnail URL to the backend
      mutation.mutate({ storyUrl: Location, description: newStoryText, storyType: fileType, videoThumbnailUrl: thumbnailUrl });
      resetModalState();
    } catch (error) {
      message.error(`Upload Failed: ${error.message}`);
    }
  };

  // Function to generate a video thumbnail

  const resetModalState = () => {
    setModalVisible(false);
    setNewStoryFile(null);
    setNewStoryText("");
    setFileType("");
  };

  const handleFileChange = ({ file }) => {
    setNewStoryFile(file);
    setFileType(file.type.startsWith("image/") ? "image" : "video");
  };

  const handleStoryClick = (storyDetails) => {
    setStoriesModalVisible(true);
    setSelectedStories(storyDetails);
  };

  const handleCusClick = () => setCusModalVisible(true);

  const handleAutoNextStories = () => {
    console.log('caleed', selectedStories)
    const length = storiesData.length;
    if (selectedStories.indexNumber >= length) {
      setStoriesModalVisible(false);
      return;
    }
    let nextStory = storiesData.find((story) => story.indexNumber === selectedStories.indexNumber + 1);
    setSelectedStories(nextStory);
  }

  return (
    <div className="stories">
      <div className="story">
        <Image
          src={currentUser.profilePic}
          alt=""
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        // preview={{ visible: false }}
        />
        <button onClick={() => setModalVisible(true)}>+</button>
      </div>

      {!cusError && !cusLoading && cusData?.length > 0 && (
        <div className="story">
          {/* <img src={cusData[0]?.storyUrl} onClick={handleCusClick} /> */}
          {
            cusData[0]?.storyType.includes("video") ? (
              <div className="storyContent">
                <img src={cusData[0]?.videoThumbnailUrl} onClick={handleCusClick} />
                <div onClick={handleCusClick} />
              </div>
            ) : (
              <div className="storyContent">
                <img src={cusData[0]?.storyUrl} onClick={handleCusClick} />
                <div onClick={handleCusClick} className="overlay" />
              </div>
            )
          }
          <span style={{ zIndex: 2 }}>Your Story</span>
        </div>
      )}

      {!storiesError && !storiesLoading && storiesData?.length > 0 && storiesData.map((story) => {
        if (story.userId === currentUser.id) return null;
        console.log("story ", story)
        return (
          <div className="story" key={story.id}>
            <span className="userprfstry"><img src={story.userProfileImagePath} width={'30px'} height={'30px'} style={{ position: 'absolute', zIndex: 2 }} /></span>
            {
              story?.storyDetails[0].storyType.includes("video") ? (
                <div className="storyContent">
                  <img src={story?.storyDetails[0].videoThumbnailUrl} onClick={() => handleStoryClick(story)} />
                  <div className="overlay" onClick={() => handleStoryClick(story)} />
                </div>
              ) : (
                <div className="storyContent">
                  <img src={story?.storyDetails[0].storyUrl} onClick={() => handleStoryClick(story)} />
                  <div className="overlay" onClick={() => handleStoryClick(story)} />
                </div>
              )
            }
            {/* <img src={story?.storyDetails[0].storyUrl} onClick={() => handleStoryClick(story)} /> */}
            <span style={{ position: 'absolute', zIndex: 2 }}>
              {story.userFirstName}
            </span>
          </div>
        );
      })}

      <Modal
        title="Add New Story"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={resetModalState}
        okButtonProps={{ disabled: !newStoryFile }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <Upload
              beforeUpload={() => false}
              onChange={handleFileChange}
              showUploadList={false}
              accept="image/*,video/*"
            >
              <Button icon={<PlusOutlined />}>Select Image/Video</Button>
            </Upload>
            <Input
              placeholder="Enter your name or text"
              value={newStoryText}
              onChange={(e) => setNewStoryText(e.target.value)}
              style={{ marginTop: 10 }}
            />
          </div>
          {newStoryFile && (
            <div style={{ marginLeft: 10 }}>
              {fileType === "image" ? (
                <Image
                  src={URL.createObjectURL(newStoryFile)}
                  alt="Selected Image"
                  width={100}
                  height={100}
                  style={{ objectFit: 'cover' }}
                />
              ) : (
                <video
                  src={URL.createObjectURL(newStoryFile)}
                  width={100}
                  height={100}
                  style={{ objectFit: 'cover' }}
                  controls
                />
              )}
            </div>
          )}
        </div>
      </Modal>

      <Dialog
        onClose={() => setStoriesModalVisible(false)}
        open={storiesModalVisible}
        sx={{
          '& .MuiDialog-paper': {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            borderRadius: 0,
            overflow: 'hidden',
            backgroundColor: "transparent",
            alignItems: "center",
          }
        }}
        fullScreen
      >
        <DialogContent
          sx={{
            padding: 0,
            margin: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              right: 30,
              zIndex: 9999,
              cursor: 'pointer',
              color: 'white'
            }}
            color="inherit"
            onClick={() => setStoriesModalVisible(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <StoriesModal selectedStories={selectedStories} currentUser={currentUser} handleAutoNextStories={handleAutoNextStories} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setCusModalVisible(false)}
        open={cusModalVisible}
        sx={{
          '& .MuiDialog-paper': {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            borderRadius: 0,
            overflow: 'hidden',
            backgroundColor: "transparent"
          }
        }}
        fullScreen
      >
        <DialogContent
          sx={{
            padding: 0,
            margin: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              right: 30,
              zIndex: 9999,
              cursor: 'pointer',
              color: 'white'
            }}
            color="inherit"
            onClick={() => setCusModalVisible(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <CusComponent cusData={cusData} setCusModalVisible={setCusModalVisible} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MyStories;
